<template>
  <div class="d-flex pt-1">
    <div class="base-timer">
      <svg
        class="base-timer__svg"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g class="base-timer__circle">
          <circle
            class="base-timer__path-elapsed"
            cx="50"
            cy="50"
            r="45"
          ></circle>
          <path
            :stroke-dasharray="circleDasharray"
            class="base-timer__path-remaining"
            :class="remainingPathColor"
            d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
          ></path>
        </g>
      </svg>
      <v-icon class="base-timer__label">mdi-timer-outline </v-icon>
    </div>

     <div class="d-flex w-100">
        <span class="body-2"
          >Reserva expira em: <br /><span class="font-weight-bold"
            >{{formattedTimeLeft}}</span
          ></span
        >
      </div>
  </div>
</template>

<script>

const FULL_DASH_ARRAY = 15*60;
const WARNING_THRESHOLD = 10*60;
const ALERT_THRESHOLD = 5*60;

const COLOR_CODES = {
  info: {
    color: "green",
  },
  warning: {
    color: "orange",
    threshold: WARNING_THRESHOLD,
  },
  alert: {
    color: "red",
    threshold: ALERT_THRESHOLD,
  },
};

const HORA    =  0;
const MINUTO  =  20;

const TIME_LIMIT =  HORA   * 3600 +
                    (MINUTO/60) * 3600;

//console.log("TIME_LIMIT", TIME_LIMIT)

export default {
  name: "Cronometro",

  data() {
    return {
      timePassed: 0,
      timerInterval: null,
      hours: "",
      minutes: "",
      seconds: "",
    };
  },

  computed: {
    circleDasharray() {
      //return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
      return `${(this.timeFraction * ALERT_THRESHOLD).toFixed(0)} 283`;
    },

    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      let hours = Math.floor(timeLeft / 60 / 60);
      let minutes = Math.floor(timeLeft / 60) - (hours*60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${hours} horas ${minutes} min e ${seconds} seg`;
    },

    timeLeft() {
      return TIME_LIMIT - this.timePassed;
    },

    timeFraction() {
      const rawTimeFraction = this.timeLeft / TIME_LIMIT;
      return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
    },

    remainingPathColor() {
      const { alert, warning, info } = COLOR_CODES;

      if (this.timeLeft <= alert.threshold) {
        return alert.color;
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color;
      } else {
        return info.color;
      }
    },
  },

  watch: {
    timeLeft(newValue) {
      if (newValue === 0) {
        this.onTimesUp();
      }
    },
  },

  mounted() {
    this.startTimer();
  },

  methods: {
    onTimesUp() {
      clearInterval(this.timerInterval);
    },

    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 30);
    },
  },
};
</script>

<style scoped lang="scss">
.base-timer {
  position: relative;
  top: -12px;
  width: 60px;
  height: 60px;
  margin-right: 8px;
  display: flex;
  align-items: center;

  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 10px;
    stroke: #c4c4c4;
  }

  &__path-remaining {
    stroke-width: 10px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.green {
      color: rgb(65, 184, 131);
    }

    &.orange {
      color: orange;
    }

    &.red {
      color: red;
    }
  }

  &__label {
    position: absolute;
    top: 5;
    left: 10.5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
