<template>
  <div>
    <NegociacoesPendentes />
    <!-- <Footer /> -->
  </div>
</template>

<script>
///+++import ModalNegociacao from "../ModalNegociacao/ModalNegociacao.vue";
// import MapaEmpreendimentos from "../EmpreendMapa/MapaEmpreendimentos.vue";
//import store_Negociacoes from "../components/Negociacoes/store_Negociacoes";
// import Footer from "../components/Footer.vue";
import NegociacoesPendentes from "../components/Negociacoes/NegociacoesPendentes";

export default {
  components: {
    NegociacoesPendentes,
    //Footer,
  },

  data() {
    return {
      //store_Negociacoes : store_Negociacoes,
    }
  },

  mounted() {
    //console.log('mounted FAVORITOS: ', this.store_Negociacoes.reservas_abertas)
  }
  
};
</script>
