<template>
  <div id="NegociacoesPendentes">
    <v-row class="mt-9 mx-0 mb-16 px-1 main-container w-100">
      <v-col cols="12">
        <div class="title-wrapper d-flex justify-space-between ml-16 pl-4">
          <h2 class="h3 main-title">
            Minhas negociações ({{
              propostas_abertas.length + reservas_abertas.length
            }})
          </h2>
        </div>
        <!-- Card -->
        <v-row class="mt-8">
          <!-- Container Tabs -->
          <!-- <v-container class="wrapper px-0"> -->
          <v-tabs class="" icons-and-text background-color="transparent">
            <v-tabs-slider></v-tabs-slider>

            <!-- Pre-Reservas ----------------- -->
            <v-tab
              class="tab-prereserva"
              href="#tab-0"
              @click="aba_selecionada = prereservas_abertas"
              ><span class="tab-text-tit mt-2">Pré Reservas</span>
              <span class="wrapper-tabs"
                ><span class="tab-text">{{ prereservas_abertas.length }}</span>
                <v-icon class="tabs-icon">mdi-bookmark-outline</v-icon></span
              >
            </v-tab>

            <!-- Reservas ----------------- -->
            <v-tab
              class="tab-reserva"
              href="#tab-1"
              @click="aba_selecionada = reservas_abertas"
              ><span class="tab-text-tit mt-2">Reservas</span>
              <span class="wrapper-tabs"
                ><span class="tab-text">{{ reservas_abertas.length }}</span>
                <v-icon class="tabs-icon">mdi-bookmark-outline</v-icon></span
              >
            </v-tab>

            <!-- Propostas ----------------- -->
            <v-tab
              class="tab-proposta"
              href="#tab-2"
              @click="aba_selecionada = propostas_abertas"
              ><span class="tab-text-tit mt-2">Propostas</span>
              <span class="wrapper-tabs"
                ><span class="tab-text">{{ propostas_abertas.length }}</span>
                <v-icon class="tabs-icon">mdi-wallet-outline</v-icon></span
              >
            </v-tab>

            <!-- Simulações ----------------- -->
            <v-tab
              class="tab-orcamento"
              href="#tab-3"
              @click="aba_selecionada = orcamentos_abertos"
              ><span class="tab-text-tit mt-2">Simulações</span>
              <span class="wrapper-tabs"
                ><span class="tab-text">{{ orcamentos_abertos.length }}</span>
                <v-icon class="tabs-icon">mdi-wallet-outline</v-icon></span
              >
            </v-tab>
 
            <!-- Conteúdo da Tab Reservas -->
            <v-tab-item
              class="tab-item pt-1"
              v-for="i in 3"
              :key="i"
              :value="'tab-' + i"
            >
              <v-col
                v-for="(lote, index) of aba_selecionada"
                v-bind:key="index"
                class="col-container px-0"
                cols="12"
              >
                <v-card class="card pa-4">
                  <v-row class="card-row pa-0">
                    <!-- Column show mobile  -->
                    <v-col
                      cols="12"
                      class="col-first-container footer-value d-flex d-md-none mb-0 pb-0 px-3"
                    >
                      <v-col class="pa-0" lg="2">
                        <div
                          class="negociacao-mobile d-flex align-center pb-1 mb-1 mr-12"
                        >
                          <span class="font-weight-bold mr-2 body-1"
                            >NEGOCIAÇÃO Nº:
                          </span>
                          <span class="subtitle font-weight-black">
                            {{ lote.cod_opor }}
                          </span>
                          <v-icon
                            color="#c4c4c4"
                            class="icon-help d-flex d-sm-none"
                            >mdi-help-circle-outline</v-icon
                          >
                        </div>
                      </v-col>

                      <div class="container-emp-mobile d-flex ml-2 mt-4 mb-2">
                        <v-avatar
                          class="profile-em mr-4 d-flex d-md-none"
                          color="grey"
                          size="50"
                        >
                          <v-img></v-img>
                        </v-avatar>
                        <div class="container-empreendimento mt-1">
                          <span
                            class="col-empreendimento font-weight-bold mr-1 body-1"
                          >
                            Empreendimento:
                          </span>
                          <span class="body-2">
                            {{ lote.empreend_nome }}
                          </span>
                        </div>
                      </div>
                      <v-icon color="#c4c4c4" class="icon-help d-none d-sm-flex"
                        >mdi-help-circle-outline</v-icon
                      >
                    </v-col>
                    <!-- Fim Column show mobile  -->

                    <!-- Card area image Desktop -->
                    <v-col
                      cols="3"
                      lg="2"
                      class="col-avatar px-3 pb-0 position-relative"
                    >
                      <span
                        class="homes-tag caption text-uppercase d-flex justify-center align-center rounded mt-3 ml-3 pa-2"
                      >
                        {{ lote.fase_descricao }}
                      </span>
                      <!-- Card img -->
                      <v-avatar rounded class="profile" color="silver">
                        <!-- <v-img
                          class="image-avatar"
                          max-width="100%"
                          max-height="100%"
                          contain
                          v-bind:src="
                            ImagemCaminhoCompleto(lote.imagem_caminho)
                          "
                        ></v-img> -->
                      </v-avatar>
                    </v-col>
                    <!-- Fim Card area image Desktop -->

                    <v-col
                      cols=""
                      class="d-flex column flex-column container-list"
                    >
                      <!-- Col Negociação Desktop -->
                      <v-col class="d-none d-md-flex justify-center mb-4">
                        <div class="container-negociacao pr-10">
                          <span class="font-weight-bold mr-1 body-1"
                            >NEGOCIAÇÃO Nº:
                          </span>

                          <span class="subtitle font-weight-black">
                            {{ lote.cod_opor }}
                          </span>
                        </div>
                      </v-col>
                      <!-- Fim Col Negociação Desktop -->

                      <v-col class="d-flex px-0" style="background: ">
                        <!-- Col Card List -->
                        <v-col
                          style="background: aqu"
                          cols=""
                          sm="4"
                          lg="4"
                          class="col-list footer-value d-flex flex-column justify-center mb-1 pt-0 pl-7 mt-n4"
                        >
                          <div class="mb-1 d-none d-md-flex align-center">
                            <span
                              class="col-empreendimento font-weight-bold body-1 mr-2"
                            >
                              Emp.:
                            </span>
                            <span class="body-2">
                              {{ lote.empreend_nome }}
                            </span>
                          </div>

                          <div class="mb-1 w-100">
                            <span class="font-weight-bold body-1"
                              >Quadra:
                            </span>
                            <span class="body-2">{{ lote.quadra }}</span>
                          </div>

                          <div class="mb-1 w-100">
                            <span class="font-weight-bold body-1">Lote: </span>
                            <span class="body-2">{{ lote.lote }}</span>
                          </div>

                          <div class="mb-1 w-100">
                            <span class="font-weight-bold mr-1 body-1"
                              >Metragem:</span
                            >
                            <span class="body-2">{{ lote.area_metro }} m²</span>
                          </div>

                          <div class="w-100">
                            <span class="font-weight-bold mr-1 body-1"
                              >Valor:</span
                            >
                            <span class="body-2"
                              >R$ {{ formatNumber(lote.preco_total) }}
                            </span>
                          </div>
                        </v-col>
                        <!-- Fim Card List -->

                        <v-col
                          style="background: aquamarin"
                          cols="12"
                          sm=""
                          lg="6"
                          class="col-status d-flex align-center pr-0"
                        >
                          <!-- Col Status -->
                          <v-col
                            style="background: blu"
                            cols="2"
                            lg="2"
                            class="col-situacao footer-value d-flex flex-column justify-center align-start mb-1 mt-n4 ml-0"
                          >
                            <span class="font-weight-bold mr-1 body-1"
                              >Situação:</span
                            >
                            <v-icon class="mr-5 mb-1" color="green"
                              >mdi-check-circle-outline</v-icon
                            >
                            <span class="body-2">{{
                              lote.fase_descricao
                            }}</span>
                          </v-col>
                          <!-- Fim Col Status -->

                          <!-- Col Item Reserva -->
                          <v-col
                            id="breakpoint-wide"
                            style="background: "
                            v-if="lote.fase_descricao == 'Reserva'"
                            cols="3"
                            lg=""
                            class="breakpoint-1440 col-cronometro footer-value d-flex justify-center align-items-start pr-0"
                          >
                            <div class="w-100 pl-7">
                              <span class="font-weight-bold mr-1 body-1"
                                >Status Reserva:</span
                              >
                              <Cronometro />
                            </div>
                          </v-col>
                          <!-- Fim Col Item Reserva -->
                        </v-col>

                        <!-- Col Card Area Action Buttons -->
                        <v-col
                          cols=""
                          class="breakpoint-1440 col-btn justify-end pr-0"
                          style="padding: 12px 10px; background: azur"
                        >
                          <div
                            class="btn-container d-flex flex-column justify-center"
                            style="width: 220px; height: 100%"
                          >
                            <v-btn
                              v-if="lote.fase_descricao == 'Reserva'"
                              class="select action-btn mb-2 text-none font-weight-regular"
                              elevation="2"
                              color="#009688">
                              <v-icon class="icon-btn mr-1">mdi-currency-usd</v-icon>
                              Efetivar Proposta
                            </v-btn>

                            <v-btn
                              v-if="lote.fase_descricao == 'Proposta'"
                              elevation="2"
                              class="action-btn send-button mb-2 text-none font-weight-regular"
                              color="indigo lighten-2"
                              v-on:click="abre_documentos(lote.cod_opor, lote.cod_fase)"
                            >
                              <v-icon class="icon-btn mr-1">mdi-paperclip</v-icon>
                              Documentação
                            </v-btn>

                            <v-btn
                              class="action-btn mb-2 w-100 text-none font-weight-regular"
                              elevation="2"
                              color="red lighten-2"
                            >
                              <v-icon class="icon-btn mr-1">mdi-close</v-icon>
                              Cancelar
                            </v-btn>

                            <v-btn
                              v-on:click="lote.fase_descricao == 'Orcamento' ?  OrcamentoRelatorio(lote.cod_orcamento) : (lote.fase_descricao == 'Reserva' ?  ReservaRelatorio(lote.cod_opor) : PropostaRelatorio(lote.cod_opor)) "
                              class="text-none font-weight-regular"
                              color="primary"
                              :loading="loading == lote.cod_opor"
                            >
                              <v-icon left dark>mdi-printer</v-icon>
                              Compartilhar
                            </v-btn>

                            <!-- Botão ativação modal -->

                            <!-- <v-btn
                                    id="mymodal"
                                    @click="dialog = true"
                                    class="select action-btn"
                                    rounded
                                    color="#009688"
                                    v-if="lote.situacao != 'Reservado'"
                                    ><v-icon class="icon-btn mr-1">mdi-paperclip</v-icon
                                    >Enviar
                                    </v-btn> -->

                            <!-- <router-link
                                    :to="{ name: 'EnvioDocumentos' }"
                                    class="router"
                                    aria-current="page"
                                  > -->
                            <!-- </router-link> -->
                          </div>
                          <!-- Fim Botão ativação modal -->
                        </v-col>
                        <!-- Col Card Area Action Buttons -->
                        <v-col
                          v-if="lote.fase_descricao == 'Proposta'"
                          cols=""
                          class="breakpoint-1440-proposta col-btn justify-end pr-0 d-none"
                          style="padding: 12px 10px; background: azur"
                        >
                          <div
                            class="btn-container d-flex flex-column justify-center"
                            style="width: 220px; height: 100%"
                          >
                            <v-btn
                              style="padding-left: 12px"
                              class="select action-btn mb-2"
                              elevation="2"
                              color="#009688"
                              v-if="lote.fase_descricao == 'Reserva'"
                              ><v-icon class="icon-btn mr-1"
                                >mdi-currency-usd</v-icon
                              >Efetivar Proposta
                            </v-btn>

                            <v-btn
                              v-if="lote.fase_descricao == 'Proposta'"
                              elevation="2"
                              class="action-btn send-button mb-4"
                              color="indigo lighten-2"
                              v-on:click="
                                abre_documentos(lote.cod_opor, lote.cod_fase)
                              "
                            >
                              <v-icon class="icon-btn mr-1"
                                >mdi-paperclip</v-icon
                              >Documentação
                            </v-btn>

                            <v-btn
                              class="action-btn mb-2 w-100"
                              elevation="2"
                              color="red lighten-2"
                            >
                              <v-icon class="icon-btn mr-1">mdi-close</v-icon>
                              Cancelar
                            </v-btn>
                            <!-- Botão ativação modal -->

                            <!-- <v-btn
                                    id="mymodal"
                                    @click="dialog = true"
                                    class="select action-btn"
                                    rounded
                                    color="#009688"
                                    v-if="lote.situacao != 'Reservado'"
                                    ><v-icon class="icon-btn mr-1">mdi-paperclip</v-icon
                                    >Enviar
                                    </v-btn> -->

                            <!-- <router-link
                                    :to="{ name: 'EnvioDocumentos' }"
                                    class="router"
                                    aria-current="page"
                                  > -->
                            <!-- </router-link> -->
                          </div>
                          <!-- Fim Botão ativação modal -->
                        </v-col>
                      </v-col>

                      <v-col
                        class="breakpoint-1024 d-none"
                        v-if="lote.fase_descricao == 'Reserva'"
                      >
                        <!--  Col Item Reserva -->
                        <v-col
                          style="background: "
                          v-if="lote.fase_descricao == 'Reserva'"
                          cols="6"
                          lg=""
                          class="dcol-cronometro footer-value d-flex justify-center align-items-start pr-0"
                        >
                          <div class="w-100">
                            <span class="font-weight-bold mr-1 body-1"
                              >Status Reserva:</span
                            >
                            <Cronometro />
                          </div>
                        </v-col>
                        <!-- Fim Col Item Reserva -->

                        <!-- Col Botão ativação modal -->
                        <v-col
                          class="col-btn-mobile d-flex px-0"
                          cols="6"
                          lg=""
                          style="padding: 12px 10px"
                        >
                          <div class="w-100 d-flex flex-column align-end px-0">
                            <v-btn
                              style="padding-left: 12px"
                              class="select action-btn mb-2 w-100"
                              elevation="2"
                              color="#009688"
                              v-if="lote.fase_descricao == 'Reserva'"
                              ><v-icon class="icon-btn mr-1"
                                >mdi-currency-usd</v-icon
                              >Efetivar Proposta
                            </v-btn>

                            <v-btn
                              v-if="lote.fase_descricao == 'Proposta'"
                              elevation="2"
                              class="action-btn send-button mb-4"
                              color="indigo lighten-2"
                              v-on:click="
                                abre_documentos(lote.cod_opor, lote.cod_fase)
                              "
                            >
                              <v-icon class="icon-btn mr-1"
                                >mdi-paperclip</v-icon
                              >Documentação
                            </v-btn>

                            <v-btn
                              class="action-btn mb-2 w-50"
                              elevation="2"
                              color="red lighten-2"
                            >
                              <v-icon class="icon-btn mr-1">mdi-close</v-icon>
                              Cancelar
                            </v-btn>
                          </div>
                          <!-- Fim Col Botão ativação modal -->
                        </v-col>
                      </v-col>
                    </v-col>
                    <!-- Fim Col Card action buttons -->

                    <!-- Col Mobile Card action buttons -->
                    <v-col class="d-flex flex-column d-sm-none">
                      <!-- Col Card Status -->
                      <v-col
                        cols="12"
                        sm=""
                        lg=""
                        class="col-status-mobile flex-column d-flex align-items-center pa-0"
                      >
                        <v-col
                          style=""
                          cols=""
                          lg=""
                          class="col-situacao-mobile footer-value d-flex flex-column mb-1 mt-n8 ml-0"
                        >
                          <span class="font-weight-bold mr-1 body-1"
                            >Situação:</span
                          >
                          <div>
                            <v-icon class="mr-2 mb-1" color="green"
                              >mdi-check-circle-outline</v-icon
                            >

                            <span class="body-2">{{
                              lote.fase_descricao
                            }}</span>
                          </div>
                        </v-col>

                        <!-- Col Item Reserva -->
                        <v-col
                          style="background: "
                          v-if="lote.fase_descricao == 'Reserva'"
                          cols="12"
                          lg=""
                          class="col-cronometro footer-value d-flex d-sm-none mt-n2 pr-0"
                        >
                          <div class="w-100">
                            <span class="font-weight-bold mr-1 body-1"
                              >Status Reserva:</span
                            >
                            <Cronometro />
                          </div>
                        </v-col>
                        <!-- Fim Col Item Reserva -->
                      </v-col>
                      <!-- Fim Card Status -->

                      <!-- Col Botão ativação modal -->
                      <v-col
                        class="col-btn-mobile d-flex px-0"
                        lg=""
                        style="padding: 12px 10px"
                      >
                        <div class="w-100 d-flex justify-space-between px-0">
                          <v-btn
                            style="padding-left: 12px"
                            class="select action-btn-mobile mb-2"
                            elevation="2"
                            color="#009688"
                            v-if="lote.fase_descricao == 'Reserva'"
                            ><v-icon class="icon-btn mr-1"
                              >mdi-currency-usd</v-icon
                            >Efetivar Proposta
                          </v-btn>

                          <v-btn
                            @click="dialog_documentos = true"
                            elevation="2"
                            class="action-btn-mobile send-button mb-2 w-50"
                            color="indigo lighten-2"
                            v-if="lote.fase_descricao == 'Proposta'"
                          >
                            <v-icon class="icon-btn mr-1">mdi-paperclip</v-icon
                            >Documentação
                          </v-btn>

                          <v-btn
                            v-on:click="lote.fase_descricao == 'Orcamento' ?  OrcamentoRelatorio(lote.cod_orcamento) : (lote.fase_descricao == 'Reserva' ?  ReservaRelatorio(lote.cod_opor) : PropostaRelatorio(lote.cod_opor)) "
                            class="action-btn-mobile mb-2 w-50"
                            color="primary"
                            :loading="loading == lote.cod_opor"
                          >
                            <v-icon left dark>mdi-printer</v-icon>
                            Compartilhar
                          </v-btn>

                          <v-btn
                            class="action-btn-mobile mb-2 w-50"
                            elevation="2"
                            color="red lighten-2"
                          >
                            <v-icon class="icon-btn mr-1">mdi-close</v-icon>
                            Cancelar
                          </v-btn>

                        </div>
                        <!-- Fim Col Botão ativação modal -->
                      </v-col>
                    </v-col>
                    <!-- Fim Mobile Col Card action buttons -->
                  </v-row>
                </v-card>
                <!-- Fim Card -->
              </v-col>
            </v-tab-item>
            <!-- Fim Conteúdo da Tab Reservas -->
          </v-tabs>
          <!--  </v-container> -->
          <!-- Fim Container Tabs -->
        </v-row>
      </v-col>
    </v-row>

    <!-- Modal Envio de Documentos -->
    <v-row justify="center">
      <v-dialog
        v-model="dialog_documentos"
        :retain-focus="false"
        height="600px"
        min-height="600px"
        max-width="600px"
      >
        <v-card class="justify-end align-center">
          <ModalEnvioDocumentos />
        </v-card>
      </v-dialog>
    </v-row>
    <!-- End Modal Envio de Documentos -->
    
    <window-portal v-model="open">
      <hello-world :msg="msg" />
    </window-portal>

    <!-- Modal Envio de Documentos -->
    <v-row justify="center">
      <v-dialog
        v-model="dialog_relatorio"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        :retain-focus="false"
      >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog_relatorio = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog_relatorio = false"
            >
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

      </v-card>
      </v-dialog>
    </v-row>
    <!-- End Modal Envio de Documentos -->

    <!-- <div id="container">
      <a  id="nav" target="_blank" href="#"> click to navigate </a> <br />
      <iframe src="" name="i_frame" id="i_frame" style="width:100%; height:600px"> </iframe>
    </div> -->

    <!-- <router-link :to="{ path: `${baseURL}rel`}" target="_blank">
      Текст
    </router-link> -->

    <!-- <a :href="$router.push({name: 'Rel1', params: {post: 1}}).href" target="_blank">
      Open Post in new tab
    </a> -->

    <!-- <form action="http://localhost:3003/index2" type="POST" >
        <input type="hidden" value="TOKEN" name="token">
        <input type="hidden" value="TESTANDO" name="testando">
        <button type="submit">
          ENVIAR
        </button>
    </form>     -->

  </div>
</template>

<script>
import { baseURL, API_REL_BASE, baseURL_API, API, API_BLOB } from "../../services/API";
import { formatNumber } from "../../services/funcoes";
import { Email } from "../../services/global"
import store_ModalEnvioDocumentos from "../ModalEnvioDocumentos/store_ModalEnvioDocumentos";
import ModalEnvioDocumentos from "../ModalEnvioDocumentos/ModalEnvioDocumentos.vue";
import Cronometro from "../Cronometro.vue";
import store_usuario from "../../store/store_usuario"
import store_Negociacoes from "./store_Negociacoes";
import store_Usuarios from "../Usuarios/store_Usuarios"
//import Rel1 from "../Relatorios/Rel1.vue"
import WindowPortal from '../Relatorios/WindowPortal.vue';
import HelloWorld from '../Relatorios/HelloWorld.vue';
import Router from 'vue-router'

export default {
  components: {
    ModalEnvioDocumentos,
    Cronometro,
    //Rel1,
    WindowPortal,
    HelloWorld
  },

  data() {
    return {
      formatNumber                : formatNumber,
      store_usuario               : store_usuario,
      store_Negociacoes           : store_Negociacoes,
      store_ModalEnvioDocumentos  : store_ModalEnvioDocumentos,
      store_Usuarios              : store_Usuarios,

      dialog_documentos           : false,
      loading                     : 0,
      mostra                      : false,
      url_relatorio               : "",
      dialog_relatorio            : false,
      relatorio                   : null,
      open                        : false,
      msg                         : "Testando Nova Página",
      vhtml                       : "",
      baseURL                     : baseURL,

      orcamentos_abertos: [],
      prereservas_abertas: [],
      reservas_abertas: [],
      propostas_abertas: [],
      aba_selecionada: [],
    };
  },

  async mounted() {
    await this.store_Negociacoes.filtra_negociacoes_abertas();
    //console.log(this.store_Negociacoes.negociacoes)
    let la_dados             = this.store_Negociacoes.negociacoes;
    this.prereservas_abertas = la_dados.filter((o) => o.fase_descricao == "Pre Reserva");
    this.reservas_abertas    = la_dados.filter((o) => o.fase_descricao == "Reserva");
    this.propostas_abertas   = la_dados.filter((o) => o.fase_descricao == "Proposta");
    this.orcamentos_abertos  = la_dados.filter((o) => o.fase_descricao == "Orcamento");
    this.aba_selecionada     = this.reservas_abertas;
  },

  methods: {
    abre_documentos(cod_opor, cod_fase) {
      this.store_ModalEnvioDocumentos.dados = {};
      this.store_ModalEnvioDocumentos.Abre_Documentos(cod_opor, cod_fase);
      this.dialog_documentos = true;
    },

    ImagemCaminhoCompleto(img_url) {
      var img = baseURL + img_url;
      return img;
    },

    removeClasse() {
      if (lote.fase_descricao == "Reserva") {
        document
          .getElementById("breakpoint-wide")
          .classList.remove("breakpoint-1440");
      }
    },

    async OrcamentoRelatorio(cod_orcamento) {
      this.loading = cod_orcamento;
      const ls_Resp = await OrcamentoRelatorio(cod_orcamento);
      this.loading = 0;
    },

    // async ReservaRelatorio(cod_opor) {
    //   let data = {
    //     nome: "VENDA.RESERVA",
    //     tipo: "pdf",
    //     parametros: [{ Name: "cod_opor", Value: cod_opor }],
    //   };
    //   this.loading = cod_opor;
    //   const ls_Resp = await Relatorio(data);
    //   this.loading = 0;
    // },

    async ReservaRelatorio(cod_opor) {
        let body = {
          nome: "GERENCIAL.RELATORIO_31",
          tipo: "json_conteudo",
          parametros: [{ }],
        };      

        // Relatório com Window Novo (WindowPortal)
        //this.open = true;

        // Relatorio com componente Vue ------------------------------------------
        //this.relatorio = await Relatorio(body);
        //this.dialog_relatorio = true;
        //console.log("ENVIANDO", this.relatorio)
        
        //this.$router.push({ name: "Rel1", params: {relatorio: this.relatorio}, target:"_blank" }, );

        //this.loading = 0;
        //const resp = await API.get(`relatorio`, JSON.stringify(body));

        // const resp = await API_REL_BASE.post(`index2`, body);
        // const { data } = resp;
        // var newWindow = window.open();
        // newWindow.document.body.innerHTML = data;

        //this.$router.push({redirect: window.location.href = baseURL + 'rel', })

        // const file = new Blob([data], { type: "text/plain" });
        // const fileURL = URL.createObjectURL(file);
        //window.open(fileURL);

        // https://stackoverflow.com/questions/64966554/creating-a-blob-from-unicodes-code-points-in-javascript

        // var newWindow = window.open();
        // newWindow.document.write("<iframe src='" + fileURL + "' frameborder='0' ></iframe>");
        // newWindow.document.close();
        // console.log("<iframe v-bind:src='" + window.URL.createObjectURL(file) + "' frameborder='0' ></iframe>");

        //var blob = new Blob([data], { type: "text/html;charset=UTF-8" });
        //var url = URL.createObjectURL(blob);
        //document.getElementById("nav").href = url;
        //document.getElementById("i-frame").src= url;

        //var b = new Blob([data],{encoding:"UTF-8",type:"text/plain;charset=UTF-8"});
        //var url = URL.createObjectURL(b);
        //window.open(url,"_blank","");

        //var newWindow = window.open();
        //newWindow.document.write(data);
        //newWindow.document.close();

        //this.url_relatorio = API_REL_BASE.post(`index2`, body);
        //var newWindow = window.open();
        //newWindow.document.location.href = this.url_relatorio;
        
        ///window.location.href = baseURL + 'rel';
        ///this.mostra = true;
        ///this.$router.push("/indexrelatorio")
        //document.write(this.url_relatorio);
        //documento.close();

        //this.url_relatorio = baseURL + 'index2' + '?cod_relatorio=2';
        //var newWindow = window.open();
        //newWindow.document.location.href = this.url_relatorio;

        // Create request object 
        // var request = new Request(baseURL + 'index2', 
        //     { method: 'POST', 
        //       body: {'name': 'Klaus'}, 
        //       //headers: new Headers({ 'Content-Type': 'application/json' }) 
        //     });
        // // Now use it! 
        // fetch(request) 
        // .then(resp => { 
        //   console.log('XXXXXXX', resp);
        //     // handle response }) 
        // })
        // .catch(err => { 
        //   console.log(err);
        //     // handle errors 
        // });

        // const resposta = await API.post("rel", {
        //                                         params: {
        //                                           teste: 2
        //                                         },
        //                                       }
        //                               );
        // if (resposta) {
        //   console.log("resposta", resposta)
        // }

        //this.url_relatorio = baseURL + 'rel';
        //var newWindow = window.open();
        //newWindow.document.location.href = this.url_relatorio;

        // var redirect = function(url, method) {
        //   console.log("ENTROU !!!")
        //     var form = document.createElement('form');
        //     form.method = method;
        //     form.action = url;
        //     form.submit();
        // };
        // redirect('http://www.example.com', 'post');

        window.io = {
          open: function(verb, url, data, target) {
            var form = document.createElement("form");
            form.action = url;
            form.method = verb;
            //form.target = target || "_self";
            if (target)
              form.target = target;
            if (data) {
                for (var key in data) {
                    var input = document.createElement("textarea");
                    input.name = key;
                    input.value = typeof data[key] === "object"
                        ? JSON.stringify(data[key])
                        : data[key];
                    form.appendChild(input);
                }
            }
            //form.style.display = 'none';
            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
          }
        }
        io.open('POST', baseURL + 'index2', { token : "TOKEN", relatorio: "Relatorio_31" }, 'i_frame');

   },

    async PropostaRelatorio(cod_opor) {
      let data = {
        nome: "VENDA.PROPOSTA",
        tipo: "html-pdf",
        parametros: [{ Name: "cod_opor", Value: cod_opor }],
      };
      this.loading = cod_opor;
      const ls_Resp = await Relatorio(data);

      // Teste de envio de Email -------------------------------------
      // Enviando email da Proposta para os Gestores e o Corretor que vendeu
      const ls_Resp2 = ls_Resp.replace("data:application/pdf;base64,","");
      const ls_RespUsuario = await this.store_Usuarios.UsuariosGet({cod_funcao: 4}); // Adicionar os gestores na lista de emails
      const la_Email = [];
      if (this.store_usuario.user.email)
        la_Email.push(this.store_usuario.user.email);
      ls_RespUsuario.rows.map((value) => {
        la_Email.push(value.email);
      })
      const mailOptions = { email       : la_Email,
                            subject     : "Younect-Imob - Uma nova proposta acabou de entrar no sistema !",
                            attachments : [{
                                            filename  : 'Proposta.pdf',
                                            content   : ls_Resp2,
                                            encoding  : 'base64'
                                          }]
                            }
      const ls_RespEmail = Email(mailOptions);
      this.loading = 0;
      // -------------------------------------------------------------

    }

  },
};
</script>

<style lang="scss" scoped>
*,
html {
  box-sizing: border-box;
  list-style: none;
  text-decoration: none !important;
}

div .main-title,
.metreage {
  font-weight: 600;
  font-size: 1.3rem !important;
  font-family: "Montserrat", sans-serif;
}

.main-title::after {
  display: block;
  width: 50px;
  height: 3px;
  margin-top: 0.5rem;
  content: "";
  background: #274abb;
}

@media (max-width: 1024px) {
  .title-wrapper {
    margin-left: 0px !important;
  }
}

@media (max-width: 599px) {
  .title-wrapper {
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  .col-container {
    padding: 0px !important;
  }
}

.wrapper {
  max-width: 1200px !important;
  background: #e0e0e0 !important;
}

/* .tab-proposta {
  width: 300px;
}

.tab-reserva {
  width: 300px;
} */

.tab-item {
  background: #e0e0e0 !important;
}

.col-first-container {
  flex-direction: column !important;
}

/*-----------------------------------*/
/*------ MINHAS NEGOCIAÇõES ------*/
/*-----------------------------------*/
.card-column {
  box-sizing: border-box !important;
  padding: 10px !important;
  display: flex;
  justify-content: center;
}

@media (max-width: 599px) {
  .card-column {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media (max-width: 1024px) {
  .main-container {
    margin-left: 0px !important;
    padding: 0px !important;
  }

  .card {
    margin: auto 0px !important;
  }

  .col-situacao {
    margin-left: 0px !important;
  }
}

.card-row {
  display: flex;
  box-sizing: border-box !important;
  padding: 16px;
}

.card-title {
  font-size: 17px !important;
  font-weight: 600;
  color: #444;
}

@media (max-width: 768px) {
  .footer-value {
    flex-direction: column !important;
  }
}

@media (max-width: 599px) {
  .footer-value {
    flex-direction: row !important;
  }
}

.action-btn {
  width: 220px !important;
}

.v-data-table {
  height: 480px !important;
  overflow-y: auto !important;
}

.v-select__selection {
  display: none !important;
}

.select {
  color: #fff;
}

.modal-title {
  background: #0548a3;
}

.modal-title {
  color: rgba(225, 225, 225, 0.95);
}

.text-white {
  font-family: "Montserrat", sans-serif !important;
  font-size: 20px;
}

.card-container {
  background: #e8e8e8;
  height: 560px;
}

.card-table {
  height: calc(100% - 203px);
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: -25px;
  border-radius: 10px 10px 5px 5px !important;
}

@media (max-width: 599px) {
  .card-table {
    height: calc(100% - 227px);
  }
}

@media (max-width: 414px) {
  .card-table {
    height: calc(100% - 275px);
  }
}

.text-intro {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500;
}

@media (max-width: 599px) {
  .text-intro {
    font-size: 12px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}

@media (max-width: 414px) {
  .text-intro {
    font-size: 12px !important;
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .show-375 {
    display: inline !important;
  }
}

@media (max-width: 375px) {
  .card-table {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

.col-avatar {
  padding-right: 0px;
}

.col-empreendimento,
.col-quadra,
.col-situacao {
  padding-left: 0px;
}

.subtitle {
  color: #3858c0;
}

.col-list {
  padding-right: 0px;
}

.icon-help {
  position: absolute;
  right: 15px;
  font-size: 28px;
}

.btn-container {
  margin-left: -50px;
}

@media (max-width: 768px) {
  .container-emp-mobile {
    margin-bottom: 20px !important;
  }

  .container-empreendimento span {
    display: flex;
  }

  .col-situacao {
    align-items: flex-start !important;
    flex-direction: column !important;
    margin-left: 4px !important;
    padding-left: 0px !important;
  }

  .col-btn {
    justify-content: center !important;
  }

  .btn-container {
    flex-direction: row !important;
    justify-content: flex-start !important;
    max-width: 100% !important;
    flex-wrap: wrap;
  }

  .btn-container .action-btn {
    margin-right: 16px;
  }
}

@media (max-width: 599px) {
  .container-emp-mobile {
    margin-bottom: -20px !important;
  }

  .col-btn,
  .col-status {
    display: none !important;
  }
}

.action-btn,
.action-btn-mobile {
  font-size: 12px;
  color: #fff;
}

.send-button {
  width: 100%;
}

.send-button:hover {
  background: #0548a3;
  color: #fff !important;
}

.icon-btn {
  font-size: 20px;
}

@media (max-width: 599px) {
  .title-wrapper {
    padding-top: 15px;
  }

  .card {
    padding: 10px !important;
    margin: 0px !important;
  }

  .main-container {
    margin: 0px !important;
    box-sizing: border-box;
    padding: 0px 10px !important;
  }

  .col-container {
    margin-bottom: 16px;
  }

  .col-first-container {
    flex-direction: column !important;
  }

  .negociacao-mobile {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 6px;
    padding-bottom: 10px !important;
    border-bottom: 1px solid rgba(225, 225, 225, 0.95);
    box-sizing: border-box;
  }

  .col-negociacao {
    display: none !important;
  }

  .icon-help {
    position: absolute;
    right: 15px;
  }

  .margin-mobile {
    margin-bottom: 10px;
  }

  .col-list {
    flex-direction: column !important;
  }

  @media (max-width: 599px) {
    .col-list {
      padding-top: 15px !important;
    }
  }

  .container-aguardando {
    flex-direction: row !important;
    margin-top: 8px !important;
  }

  .container-empreendimento {
    margin-top: 0px !important;
  }

  .col-empreendimento {
    display: flex;
  }
}

@media (max-width: 375px) {
  .title-wrapper {
    padding-left: 0px !important;
  }
  .col-avatar {
    padding-right: 15px !important;
  }
  .col-list {
    padding-left: 40px;
  }

  .col-cronometro {
    padding-left: 4px !important;
  }
}

.homes-tag {
  position: absolute;
  width: 196px !important;
  height: 30px;
  font-weight: 600;
  background: #18ba60;
  color: #fff;
  z-index: 1;
}

@media (max-width: 1024px) {
  .homes-tag {
    width: 136px !important;
    margin-top: 50px !important;
  }
}

@media (max-width: 768px) {
  .homes-tag {
    width: 128px !important;
    margin-top: 10px !important;
  }
}

@media (max-width: 599px) {
  .homes-tag {
    width: 98px !important;
    margin-top: 50px !important;
  }
}

.profile {
  width: 220px !important;
  height: 200px !important;
}

@media (max-width: 1024px) {
  .profile {
    width: 160px !important;
    height: 160px !important;
    margin-top: 40px;
  }

  .container-negociacao {
    margin-right: 72px;
  }
}

@media (max-width: 768px) {
  .profile {
    width: 150px !important;
    height: 150px !important;
    margin-top: 0px;
  }
  .col-list {
    padding-left: 0px !important;
  }
}

@media (max-width: 599px) {
  .container-list {
    margin-left: 32px;
  }
  .profile {
    width: 120px !important;
    height: 120px !important;
    margin-top: 40px;
  }
  .col-list {
    margin-top: 20px !important ;
  }

  .col-btn-mobile div {
    flex-direction: column !important;
  }

  .action-btn-mobile {
    width: 100% !important;
  }
}

@media (max-width: 375px) {
  .container-list {
    margin-left: 55px;
  }

  .action-btn-mobile {
    width: 100% !important;
  }

  .action-btn-mobile:first-child {
    margin-bottom: 15px !important;
  }
}

@media (max-width: 1024px) {
  .breakpoint-1440 {
    display: none !important;
  }

  .breakpoint-1440-proposta {
    display: flex !important;
    margin-left: -100px !important;
  }

  .breakpoint-1024 {
    display: flex !important;
  }
}

@media (max-width: 768px) {
  .breakpoint-1440-proposta {
    margin-left: -80px !important;
  }
}

@media (max-width: 599px) {
  .breakpoint-1024 {
    display: none !important;
  }

  .breakpoint-1440-proposta {
    display: none !important;
  }
}

@media (max-width: 375px) {
  .col-situacao-mobile {
    padding-left: 4px;
  }
}

/*-----------------------------------*/
/*------ TABS ------*/
/*-----------------------------------*/
.v-tabs {
  max-width: 1310px;
  margin: auto;
}

@media (max-width: 1024px) {
  .v-tabs {
    max-width: 960px;
  }
}

@media (max-width: 768px) {
  .v-tabs {
    max-width: 704px;
  }
}

@media (max-width: 599px) {
  .v-tabs {
    padding: 0px !important;
  }
}

.tab-orcamento,
.tab-proposta,
.tab-reserva {
  width: 150px;
  font-size: 0.3rem !important;
  border-radius: 8px;
  background: #f7f7f7ea;
}

.tab-orcamento span,
.tab-reserva span,
.tab-proposta span {
  text-align: left;
  width: 100%;
}

.wrapper-tabs {
  width: 100%;
}

.tab-text {
  font-size: 1.5rem !important;
  position: relative;
  top: 0px;
}

.tab-text-tit {
  font-size: 0.9rem !important;
}

.tabs-icon {
  font-size: 2rem !important;
  float: right;
  /* margin-left: 50px!important; */
}

.tab-item {
  background: #e0e0e0 !important;
  //padding-top: 20px !important;
}

.v-tab--active {
  background: #3959be !important;
  color: #fff !important;
  border-bottom: 0px !important;
}

.v-tabs-slider {
  background: #3959be !important;
  display: none !important;
}

.nav-link {
  color: #a7a9aa;
}

</style>
