<template>
  <div id="ModalEnvioDocumentos">
    <v-card v-for="(header, i) in store_ModalEnvioDocumentos.dados" :key="i">
      <v-card-title 
        v-if="i == 0"
        :style="{ 'background-color':COR_PRINCIPAL }"
      >
        <div class="d-flex pl-4 modal-title" style="width:100%">
          <span class="text-white">Envio de Documentos</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            color="#F2F6F7"
            @click="dialog = false"
            class="mt-n2"
          >
            <v-icon @click="closeModal">mdi-close</v-icon>
          </v-btn>
        </div>

        <h4 class="text-white body-1 text-center mx-auto pt-2 pb-4 px-8">
          Use esta tela para enviar seus documentos
          <br class="d-none show-375" />para análise.<br />
          Basta clicar no botão de "ENVIAR" ao lado de cada tipo.
          <br class="d-none show-375" /><br />
          Em caso de dúvidas sobre o envio,
          <br class="d-none show-375" />clique no botão de ajuda
          <v-icon color="#e1e1e1f2" class="icon-help">
            mdi-help-circle-outline
          </v-icon>
        </h4>
      </v-card-title>

      <v-card-text class="card-table px-2">
        <!-- Container Documentos Básicos -->
        <v-container class="mb-3 pt-0 px-0">
          <div >
            <!-- -- linha dos documentos ------------------------------------------ -->
            <v-expansion-panels
              class="pa-4 pt-0 px-0 expansion-panel"
              accordion
            >
              <v-expansion-panel>
                <v-expansion-panel-header
                  class="panel-header font-weight-bold"
                  disable-icon-rotate
                >
                  {{ header.grupo_documento }}
                  <template v-slot:actions>
                    <v-icon color="" class="icon-help">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4">
                  Conteúdo teste
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-row
              v-for="(doc, index) of header.grupo_documento_itens"
              v-bind:key="index"
              class="table-row mx-0 d-flex align-center"
            >
              <!-- Icon -->
              <v-col class="column-status" cols="1">
                <!-- <pre>{{ doc }}</pre> -->
                <v-icon
                  v-if="doc.situacao == 'Aprovado'"
                  class="mr-2 status-icon"
                  color="green"
                >
                  mdi-check-circle
                </v-icon>
                <v-icon
                  v-if="doc.situacao == 'Reprovado'"
                  class="mr-2 status-icon"
                  color="red"
                >
                  mdi-close-circle
                </v-icon>
                <v-icon
                  v-if="doc.situacao == 'Aguardando Aprovação'"
                  class="mr-2 status-icon"
                  color="#FFC866"
                >
                  mdi-alert-circle
                </v-icon>
                <v-icon
                  v-if="!doc.situacao"
                  class="mr-2 status-icon"
                  color="#D0D0D0"
                >
                  mdi-help-circle
                </v-icon>
              </v-col>
              <!-- End Icon -->

              <!-- Descricao/ Data/ Situação/ Motivo -->
              <v-col
                cols="6"
                md="7"
                class="column-description body-2 d-flex flex-column"
              >
                <span class="font-weight-bold" style="color: #4f7ebd">
                  {{ doc.tipo_documento }}
                </span>
                <span
                  class="font-italic"
                  style="color: #C0C0C0; font-size: 14px !important"
                  v-if="doc.situacao"
                  >Enviado {{ formatadata(doc.cadastro_dt) }}
                </span>
                <span
                  style="font-size: 14px !important"
                  :style="
                    doc.situacao == 'Aprovado'
                      ? 'color:#469c47'
                      : doc.situacao == 'Reprovado'
                      ? 'color:#FF0000'
                      : 'color:#FFC866'
                  "
                  >{{ doc.situacao }}</span
                >
                <span style="color: #a0a0a0">{{ doc.situacao_motivo }}</span>
              </v-col>
              <!-- End Tipo/ Data/ Situação/ Motivo -->

              <!-- Dialog File -->
              <v-col cols="1" class="column-eye d-flex justify-end">
                <v-btn
                  v-if="doc.situacao"
                  @click="lerArquivo(doc), dialogFile = true"
                  icon
                  color="#bdbdbd"
                >
                  <v-icon class="eye-icon"> mdi-eye </v-icon>
                </v-btn>
              </v-col>
              <!-- End Dialog File -->

              <!-- Action Buttons -->
              <v-col cols="4" md="3" class="column-action">
                <!-- Button Enviar -->
                <v-btn
                  v-if="!doc.situacao"

                  @click="gedTipoDoc({tipo_cadastro: 'pessoa\',\'lead'}, doc)"
                  class="send-button rounded-pill float-right"
                  outlined
                  color="primary"
                >
                  <v-icon class="clip-icon mr-1" > mdi-paperclip </v-icon>
                  <span>enviar</span>
                </v-btn>
                <!-- End Button Enviar -->

                <!-- Button Reenviar -->
                <v-btn
                  v-if="doc.situacao == 'Reprovado'"
                  class="resend-button rounded-pill float-right"
                  color="red"
                  @click="gedTipoDoc({tipo_cadastro: 'pessoa\',\'lead'}, doc)"
                  outlined
                >
                  <v-icon class="clip-icon mr-1"> mdi-paperclip </v-icon>
                  <span>reenviar</span>
                </v-btn>
                <!-- End Button Reenviar -->

                <!-- Button Dots -->
                <v-menu v-if="doc.situacao == 'Aguardando Aprovação'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="float-right">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="(item, i) in items"
                      :key="i"
                      @click="() => {}"
                    >
                      <v-list-item-title
                        v-if="item.title == 'Aprovar'"
                        @click="dialogoAprovar(doc, 'Aprovado')"
                      >
                        <v-icon color="green" class="mr-2 icon-menu"
                          >mdi-check
                        </v-icon>
                        {{ item.title }}
                      </v-list-item-title>

                      <v-list-item-title
                        v-else-if="item.title == 'Reprovar'"
                        @click="dialogoAprovar(doc, 'Reprovado')"
                        ><v-icon color="red" class="mr-2 icon-menu"
                          >mdi-close</v-icon
                        >
                        {{ item.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!-- End Button Dots -->
              </v-col>
              <!-- End Action Buttons -->
            </v-row>
            <!-- FIM linhas dos documentos ------------------------------------------ -->

          </div>
        </v-container>
      </v-card-text>
    </v-card>

    <!--------- DIALOG NOVO DOCUMENTO ------------------------------->
    <v-container
      v-if="dialogNovoDocumento" >
      <v-dialog
        v-if="dialogNovoDocumento" 
        v-model="dialogNovoDocumento" 
        persistent
        max-width="440px">
        <v-card v-bind:style="{ 'background-color':COR_SECUNDARIA }" class="container pa-0">
          <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between py-2 px-3">
            <span class="text-white title-page body-1">Novo Documento</span>
            <v-btn @click="dialogNovoDocumento = false" icon dark color="#F2F6F7">
              <v-icon large class="title">mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="wrapper pt-1 px-0 pb-13">
            <v-container class="px-7">
              <v-row>
                <v-col class="pb-0 px-0">
                  <v-form v-model="valid" ref="form_doc">
                    <v-autocomplete 
                      label="Tipo de Documento" 
                      v-model="dados_tipo.cod_ged_tipo_doc" 
                      filled 
                      light 
                      dense 
                      readonly
                      return-object
                      background-color="#FFF" 
                      required
                      :items="arrayTipoDocumento" 
                      item-value="cod_ged_tipo_doc"
                      item-text="tipo_documento"
                      :rules="rulesTipoDocumento">
                    </v-autocomplete>

                    <DatePicker 
                      label="Data do Vencimento" 
                      placeholder="dia/mês/ano" 
                      outlined 
                      v-if="dados_tipo.possui_validade == 'SIM'"
                      v-model="validadeAux" 
                      backgroundColor="#FFF"  
                      tabindex="0"
                      required
                      dense 
                      :rules="rulesVencimentoDocumento"
                      format="DD/MM/YYYY"
                    ></DatePicker>

                    <v-file-input 
                      v-model="image" 
                      @change="readURL" 
                      prepend-icon="mdi-camera" 
                      background-color="#FFF" 
                      small-chips 
                      filled 
                      required
                      light 
                      dense 
                      :rules="rulesDocumentos"
                      class="mt-n2" label="Enviar Documento">
                    </v-file-input>
                    <v-img :aspect-ratio="1" :src="url()" v-if="image" max-width="150px" style="margin:auto"> </v-img>

                  </v-form>
                </v-col>
              </v-row>
            </v-container>

            <v-divider class="mx-4 mb-2"></v-divider>

          </v-card-text>

          <v-footer v-bind:style="{ 'background-color':COR_SECUNDARIA }" absolute elevation="0" class="d-flex justify-center pa-3">
            <v-btn @click="dialogNovoDocumento = false; validadeAux = null; image = null; dados_tipo.cod_ged_tipo_doc = null" class="mr-4 btn caption font-weight-medium" color="primary" text>
              Cancelar
            </v-btn>

            <v-btn 
              :disabled="!valid" 
              @click="validate()" 
              class="btn white--text caption font-weight-medium" 
              :loading="loading_salvar" 
              color="primary accent-4">
              Salvar
            </v-btn>
          </v-footer>
        </v-card>
      </v-dialog>
    </v-container>
    <!--------- FIM DIALOG NOVO DOCUMENTO ------------------------------->

    <!--------- DIALOG VER IMAGEM DOCUMENTO ------------------------------->
    <div
      v-if="dialogFile">
      <v-dialog
        v-model="dialogFile" 
        @keydown.esc="dialogFile = false"
        transition="dialog-bottom-transition"  
        persistent 
        :retain-focus="false">
        <v-card style="background-color:#FFFFFF">
          <v-card-title
            v-bind:style="{ 'background-color':COR_PRINCIPAL }"
            class="pa-0 mt-n2"
            style="height:55px!important">
            <!-- <span class="text-white title-page body-1">Detalhe do Documento</span> -->
            <v-col cols="1" align="left">
              <!-- Button Dots -->
              <v-menu v-if="imagem_selecionada.situacao == 'Aguardando Aprovação'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="#F2F6F7" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                    @click="() => {}"
                  >
                    <v-list-item-title
                      v-if="item.title == 'Aprovar'"
                      
                      @click="dialogoAprovar(doc_aux, 'Aprovado')"
                    >
                      <v-icon color="green" class="mr-2 icon-menu"
                        >mdi-check
                      </v-icon>
                      {{ item.title }}
                    </v-list-item-title>

                    <v-list-item-title
                      v-else-if="item.title == 'Reprovar'"
                      @click="dialogoAprovar(doc_aux, 'Reprovado')"
                      ><v-icon color="red" class="mr-2 icon-menu"
                        >mdi-close</v-icon
                      >
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- End Button Dots -->
            </v-col>
            <v-col cols="1" class="ml-2">
              <v-btn
                icon
                color="#F2F6F7"
                class="mt-0"
                @click="forceFileDownload(imagem_selecionada.imagem_URL);"
                >
                <v-icon color="#F2F6F7">mdi-download</v-icon>
              </v-btn>
            </v-col>
            <v-col align="right">
              <v-btn
                icon
                dark
                color="#F2F6F7"
                @click="dialogFile = false; imagem_selecionada = {}"
                >
                  <v-icon large class="title">mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-card-title>
        <v-card-text 
          class="px-0 py-0">
          <v-row no-gutters
            v-if="tipo_imagem === 'jpg' || tipo_imagem === 'png' || tipo_imagem === 'bmp'"
            :loading="loading">
            <v-col cols="12">
                <pinch-zoom>
                  <v-img
                    class="altura"
                    :src="imagem_selecionada.imagem_URL"
                    contain>
                    <!-- <div class="fill-height top-gradient" style="text-align: center"></div> -->
                  </v-img>
                </pinch-zoom>
              </v-col>
          </v-row>
          <v-row no-gutters
            v-else-if="tipo_imagem === 'pdf'"
            :loading="loading">
            <v-col cols="12">
              <iframe class="altura" :src="imagem_selecionada.imagem_URL" frameborder="0"></iframe>
            </v-col>
          </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
   
<!--------- DIALOG APROVAR DOCUMENTO ------------------------------->
    <v-dialog 
      v-model="dialogConfirm" 
      transition="dialog-bottom-transition" 
      persistent 
      max-width="410" 
      class="pa-0">
      <v-divider></v-divider>

      <v-card elevation="0" class="">
        <v-card-title class="text-h6">
          <p>Tem certeza que deseja {{ situacao_aux }} este documento?</p>
        </v-card-title>
        <v-card-text>
          <span style="font-size: 15px; font-weight:600">Tipo do documento: {{ tipo_documento_aux }}</span><br><br>

          <v-form v-model="valid_situacao" ref="situacao_form">

            <v-textarea
              v-if="situacao_aux == 'Reprovado'"
              rows="3"
              v-model="situacao_motivo_aux"
              auto-grow 
              counter="200" 
              background-color="#FFF"
              label="Motivo da Reprova"
              placeholder="Motivo da Reprova"
              dense
              outlined
              autofocus
              clearable
              :rules="rulesSituacaoMotivo"
              required
            />

          </v-form>
          
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialogConfirm = false; doc_aux = null">
            Cancelar
          </v-btn>

          <v-btn class="btn white--text" color="primary accent-4" :disabled="!valid_situacao"  :loading="loading_aprovar"  @click="aprovar()">
            {{ situacao_aux}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { formatDateToLocaleString } from "../../services/funcoes";
import { API, baseURL } from "../../services/API";
import {formatDateTime, resizeImage, blobToBase64 } from "../../services/funcoes";
import store_ModalEnvioDocumentos from "./store_ModalEnvioDocumentos";
import store_Ged from "../Ged/store_Ged"
import store_site from "../../store/store_site"
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SUBTITULO } from "../../services/constantes";
import DatePicker from "../ComponentesGlobais/Date-Picker/date-picker.vue";
import PinchZoom from 'vue-pinch-zoom';

export default {
  components: {
    DatePicker,
    PinchZoom
  },

  props: ['dialog_documentos'],

  data() {
    return {
      store_ModalEnvioDocumentos  : store_ModalEnvioDocumentos,
      store_Ged                   : store_Ged,
      store_site                  : store_site,
      imagem_selecionada          : {},
      tipo_imagem                 : "",
      tipo_documento_aux          : null,
      situacao_aux                : null,
      situacao_motivo_aux         : null,
      loading                     : false,
      dialogNovoDocumento         : false,
      arrayTipoDocumento          : null,
      tipo_documento              : null,
      vencimento_visivel          : false,
      image                       : null,
      loading_salvar              : null,
      loading_aprovar             : null,
      validadeAux                 : null,
      cod_ged_aux                 : null,
      doc_aux                     : null,

      resizedImg                  : '',
      COR_PRINCIPAL               : COR_PRINCIPAL,
      COR_SUBTITULO               : COR_SUBTITULO,
      COR_SECUNDARIA              : COR_SECUNDARIA,

      dados_tipo: {
        cod_ged_tipo_doc: null,
        tipo_documento: "",
        possui_validade: ""
      },
      valid                       : false,
      valid_situacao              : false,
      /* Container  */
      container: {
        receipt: "",
        message: "",
      },
      /* Aprovação Dialog Aprovar */
      approved: false,
      /* Dialog Arquivo de Imagem */
      dialogFile: false,
      /* Dialog Menu Aprovar */
      dialogConfirm: false,
      /* Dialog Menu Reprovar */
      dialogReprove: false,
      /* Menu Aprovação (Button Dots) */
      items: [
        {
          title: "Aprovar",
        },
        {
          title: "Reprovar",
        },
      ],
      
      /* Formulário Reprovação */
      form: false,
      rules: {
        required: (v) => !!v || "Preencha o motivo para continuar",
      },
         rulesDocumentos: [
        (value) => !!value || "O documento é obrigatório",
      ],

      rulesTipoDocumento: [
        (value) => !!value || "O tipo de documento é obrigatório",
      ],

      rulesVencimentoDocumento: [
        (value) => !!value || "A data do vencimento é obrigatório",
      ],

       rulesSituacaoMotivo: [
        (value) => !!value || "O Motivo da Reprova é obrigatório",
        (value) =>
          (value && value.length <= 200) ||
          "O Motivo da Reprova deve ter menos do que 200 caracteres",
      ],
    };
  },

  mounted() {
  },
  watch : {
   
  },
  methods: {
    async dialogoAprovar(){
      console.log("🚀 ~ file: ModalEnvioDocumentos.vue:580 ~ dialogoAprovar ~ situacao:")
      // this.tipo_documento_aux = doc.tipo_documento; 
      // this.situacao_aux = situacao
      // this.cod_ged_aux = doc.cod_ged
      // console.log('dialogo aprovar', this.tipo_documento_aux, this.situacao_aux, this.cod_ged_aux);
      // this.dialogConfirm = true; 
    
    },
    async aprovar(){
      const lb_valido = this.$refs.situacao_form.validate();

      if (lb_valido) {
        
        this.loading_aprovar = true
        
        let  ls_JSON = {
          acao : this.situacao_aux,
          cod_ged : this.cod_ged_aux,
          situacao_motivo : this.situacao_motivo_aux ?? null
        }

        const ls_Res = await this.store_ModalEnvioDocumentos.AprovaGedPut(ls_JSON);
      
        if (ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') {
          this.store_site.alert_cor     = "#00A000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg     = ls_Res.result.result.trim();
          this.store_site.alert         = true;

          const {cod_cliente, cliente_cod_pessoa_socio, cliente_cod_pessoa_conjuge} = this.store_ModalEnvioDocumentos.item_aux
          this.store_ModalEnvioDocumentos.Abre_Documentos(cod_cliente, cliente_cod_pessoa_socio, cliente_cod_pessoa_conjuge);

        } else {
          this.store_site.alert_cor     = "#A00000";
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg     = ls_Res.errors.trim();
          this.store_site.alert         = true;
        }
        this.loading_aprovar = false;
        
        this.dialogConfirm = false
        this.situacao_motivo_aux = null
      }
    },

    async validate() {
      const lb_valido = this.$refs.form_doc.validate();
      this.loading_salvar = true
     
      let ls_Res;
      if (lb_valido) {
        let ls_tipo = this.image.name.split(".")

        if (this.originalImg) {
          var ls_document_original = this.originalImg.split("base64,");
          ls_document_original = ls_document_original[ls_document_original.length - 1]
        }
 
        if (this.resizedImg) {
          var ls_document_thumb = this.resizedImg.split("base64,"); 

          ls_document_thumb = ls_document_thumb[ls_document_thumb.length - 1]
        } else {
          ls_document_thumb == null
        }

         if(!this.validadeAux){
          this.validadeAux = moment("01/01/2900",'DD/MM/YYYY').format()
        }
       
        var lo_JSON = {
          tipo_documento             : this.dados_tipo.tipo_documento,
          cod_ged_tipo_doc           : parseInt(this.dados_tipo.cod_ged_tipo_doc),
          tipo_arquivo               : ls_tipo[ls_tipo.length - 1],
          documento_base64           : ls_document_original,
          documento_miniatura_base64 : ls_document_thumb,
          validade                   : this.validadeAux,
          tipo_cadastro              : this.arrayTipoDocumento[0].tipo_cadastro,          
        };

        let campo_vinculo = this.arrayTipoDocumento[0].campo_vinculo
        let valor_campo_vinculo
        if (campo_vinculo === 'cod_pessoa') {
          campo_vinculo = { cod_pessoa : this.dados_tipo.cod_pessoa_referencia}
        }
        else if (campo_vinculo === 'cod_opor'){
          campo_vinculo = { cod_opor : this.store_ModalEnvioDocumentos.cod_opor_aux}
        }
        Object.assign(lo_JSON, campo_vinculo)

        ls_Res = await this.store_Ged.postGed(lo_JSON);
        if (ls_Res.message == "success") {
          this.store_site.alert_cor = "green";
          this.store_site.alert_type = "success";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Res.result;
          this.store_site.alert = true;
        } else {
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_cor = "red";
          this.store_site.alert_type = "warning";
          this.store_site.alert_msg = ls_Res.errors;
          this.store_site.alert = true;
        }

        this.dialogNovoDocumento = false;
        this.image = null
        this.validadeAux = null
        ls_document_thumb = null
        this.resizedImg = null
        lo_JSON = {}

        const {cod_cliente, cliente_cod_pessoa_socio, cliente_cod_pessoa_conjuge} = this.store_ModalEnvioDocumentos.item_aux
        this.store_ModalEnvioDocumentos.Abre_Documentos(cod_cliente, cliente_cod_pessoa_socio, cliente_cod_pessoa_conjuge);

        this.store_ModalEnvioDocumentos.cod_pessoa_aux = null
        this.store_ModalEnvioDocumentos.cod_opor_aux = null
        this.loading_salvar = false
      }
    },
     readURL(file) {
      if (!file) {
        return
      }
      // console.log("file:::::::::: ", file)
      // START: preview original
      // you can remove this section if you don't like to preview original image

      const reader = new FileReader();
      reader.onload = (e) => {
        this.originalImg = e.target.result;
        // console.log("originalImg:::::: ", this.originalImg)

        if (file.type.match(/image.*/)) {
          // START: preview resized
          resizeImage({
            file: file,
            maxSize: 170
          }).then(async (resizedImage) => {
            this.resizedImg = await blobToBase64(resizedImage);

          }).catch((err) => {
            console.error(err);
          });
          // END: preview resized
        }

      };
      reader.readAsDataURL(file); // convert to base64 string
      // END: preview original
    },
    async gedTipoDoc(p_params, doc){
      const ls_Rel = await API.get(`ged_tipo_documento`, { params: {...p_params, tipo_cadastro_descricao: doc.tipo_cadastro_descricao}});

      this.arrayTipoDocumento =  await ls_Rel.data.result.filter(el => el.tipo_documento === doc.tipo_documento)
      this.dados_tipo = {...this.arrayTipoDocumento[0]}
      this.dados_tipo.cod_pessoa_referencia = doc.cod_pessoa_referencia;
     
      this.dialogNovoDocumento = true;
    },
    url() {
      if (!this.image)
        return;

      return URL.createObjectURL(this.image);
    },
    async forceFileDownload(fileName) {

      try {
        let nome_arquivo = fileName.split('/');

        const response = await fetch(fileName)
        const blob = await response.blob();
        const url = await URL.createObjectURL(blob)
        const a = document.createElement("a");
        a.href = url;
        a.download = nome_arquivo[nome_arquivo.length - 1];
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (err) {
        console.log({
          err
        })
      }
    },
    closeModal(val){
      this.$emit('close')                              // Executando funcão no Pai
      //this.$emit('update:dialog_documentos', false); // Outra opção para alterar a variavel do Pai
    },
    formatadata(p_data) {
      return moment(p_data).format('DD/MM/YYYY HH:mm:ss');
      //return moment(doc.data_envio).format('DD/MM/YYYY, hh:mm:ss');
      //console.log(formatDateToLocaleString(moment()));
      //return moment().format('MMMM Do YYYY, h:mm:ss a')
    },

    openFileDialog() {
      // this.$refs.inputDocument.value = null;
      this.$refs.inputDocument.click();
    },

    /* Método que envia o arquivo de imagem */
    async enviaArquivo(elem, { target }) {

      let reader = new FileReader();
      if (target.files && target.files.length > 0) {
        reader.readAsDataURL(target.files[0]);

        reader.onload = async (file) => {
          // 	props.editFaseDocumento(data);
          // } else {
          // 	props.addFaseDocumento(data);
          // }

          var ls_JSON = {
            imagem: file.target.result,
            doc_grupo: elem.doc_grupo,
            doc_tipo: elem.doc_tipo,
            descricao: elem.descricao,
            cod_fase_etapa_doc: elem.cod_fase_etapa_doc,
            cod_opor_fase_etapa: elem.cod_opor_fase_etapa,
            pessoa_grupo: 0,
          };
          //console.log("RESP 1", ls_JSON);
          var ls_cod_opor = this.store_ModalEnvioDocumentos.cod_opor;
          var ls_cod_fase = this.store_ModalEnvioDocumentos.cod_fase;
          const resposta = await API.post(`fase_documentos`, ls_JSON);
          
          await this.store_ModalEnvioDocumentos.Abre_Documentos(
            ls_cod_opor,
            ls_cod_fase
          );
          //console.log("RESP 2", resposta);
        }
      }
    },

    /* Método que envia o arquivo de imagem */
    async lerArquivo(elem) {
      this.doc_aux = elem
      this.loading = true
      this.imagem_selecionada = {};

      const resposta = await API.get('get_ged', {
				params: {
					cod_ged: elem.cod_ged,
					cod_pessoa: elem.cod_pessoa,
          cod_opor : elem.cod_opor
				},
			});
      if (resposta) {
        // console.log('lerArquivo:::', resposta);
        this.imagem_selecionada = resposta.data.result[0];
        this.imagem_selecionada.imagem_URL = baseURL + "imagens/ged/empresa_" + this.imagem_selecionada.cod_empresa + "/pessoa/" + this.imagem_selecionada.imagem_caminho;
        console.log("🚀 ~ file: ModalEnvioDocumentos.vue:834 ~ lerArquivo ~ this.imagem_selecionada.imagem_URL:", this.imagem_selecionada.imagem_URL)
        this.tipo_imagem  = resposta.data.result[0].tipo_arquivo;
      }
      // cod_ged = null
      // cod_pessoa =  null,
      // cod_opor = null
      this.loading = false

    },
    /* Método que recebe motivo de reprovação */
    sendMessage() {
      this.documentos.motivo = this.container.message;
    },
  },
};
</script>

<style scoped>
/*-----------------------------------*/
/*------ TABLE ------*/
/*-----------------------------------*/
.accordion-panel {
  box-shadow: none !important;
}

.v-expansion-panel {
  border-bottom: 1px solid #c4c4c4 !important;
  border-radius: 10px 10px 5px 5px !important;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

table tr {
  width: 100%;
}

.table-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.table-row:hover {
  background: #f5f5f5 !important;
}

.panel-header {
  font-family: "Montserrat", sans-serif !important;
  font-size: 17px;
  background: #f6f6f6 !important;
  color: #4476ba;
}

.status-icon {
  font-size: 27px !important;
}

.eye-icon {
  color: #bdbdbd;
  font-size: 26px !important;
}

.input-file {
  width: 110px !important;
  height: 35px !important;
  border-radius: 50%;
  position: absolute !important;
  opacity: 0 !important;
  cursor: pointer !important;
  outline: none !important;
  z-index: 2;
}

.icon-help {
  font-size: 28px;
  opacity: 0.6;
}

.icon-menu {
  font-size: 20px;
}

.send-button {
  width: 110px;
  transition: 0.2s;
}

.resend-button {
  width: 120px;
  transition: 0.2s;
}

.send-button:hover,
.resend-button:hover {
  background: #0548a3;
  color: #fff !important;
}

.v-data-table {
  height: 480px !important;
  overflow-y: auto !important;
}

.v-select__selection {
  display: none !important;
}

.select {
  color: #fff;
}

.text-white {
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif !important;
  font-size: 20px;
}

.card-container {
  background: #e8e8e8;
  height: 560px;
}

.card-table {
  height: calc(100% - 20px);
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: -25px;
  border-radius: 10px 10px 5px 5px !important;
}

@media (max-width: 599px) {
  .card-table {
    height: calc(100% - 22px);
  }
}

@media (max-width: 414px) {
  .card-table {
    height: calc(100% - 275px);
  }
}

.altura {
  /* margin-top: 40px !important; */
  min-width: 100%;
  max-width: 100%;
  height: calc(100vh - 30vh) !important;
}

.sortable-handler {
  touch-action: none;
}

.owl-carousel {
-ms-touch-action: pan-y;
touch-action: pan-y;
}

.top-gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.75) 0%,
    transparent 72px
  );
}

</style>